.rtl-less(@ltr-property, @rtl-property, @value) {
  [dir='ltr'] & {
    @{ltr-property}: @value;
  }

  [dir='rtl'] & {
    @{rtl-property}: @value;
  }
}

@primary: var(--mainTheme);
@actionColor: #ff5501;
@main-color: #1f1f1f;
.ant-table-wrapper {
  .ant-pagination {
    padding-right: 12px !important;
  }
}

.ant-layout {
  background-color: #f0f2f6 !important;
}
.ant-table-column-sort {
  background: #fafafa !important;
}
.ant-table-cell {
  word-break: break-all;
}
.ant-pro-table-column-setting-overlay {
  .ant-popover-inner-content {
    width: 250px !important;
  }
}

.ant-pro-table-column-setting-list-item-title {
  max-width: none !important;
  // min-width: 80px;
}
